<template>
  <div id="chatMessages">
    <div class="spinner" id="messagesLoadingSpinner"></div>
    <ChatMessage
      v-for="(ticket, index) in tickets"
      v-bind:index="index"
      v-bind:key="ticket.primaryKey"
      v-bind:ticket="ticket"
      v-bind:processPrimaryKey="ticketProcess.primaryKey"
      @answer="answer"
    />
  </div>
</template>

<script>
import ChatMessage from "@/components/ChatMessage";
import ticketFunctions from "@/functions/ticket";

export default {
  name: "ChatHistory",
  components: {
    ChatMessage,
  },
  data() {
    return {
      tickets: [],
    };
  },
  props: {
    ticketProcess: Object,
  },
  watch: {
    ticketProcess: function () {
      this.tickets = [];
      this.beginnLoadingTickets();
    },
  },
  methods: {
    getChatMessagesDiv() {
      return document.getElementById("chatMessages");
    },
    getMessagesLoadingSpinner() {
      return document.getElementById("messagesLoadingSpinner");
    },
    answer(ticket, answerType) {
      this.$emit("answer", ticket, answerType);
    },
    scrollToBottom() {
      var chatMessages = this.getChatMessagesDiv();
      chatMessages.scrollTop = chatMessages.scrollHeight;
    },
    async beginnLoadingTickets() {
      if (!(await this.loadNewestTicket())) return;

      this.scrollToBottom();

      var maxTries = 3;

      // load more childs when the ChatMessagesDiv has no scrollBar
      while (maxTries > 0 && this.getChatMessagesDiv().scrollTop == 0) {
        maxTries--;
        if (!(await this.loadNextTicket())) break;
        this.scrollToBottom();
        this.$forceUpdate();
      }

      this.scrollToBottom();

      // add listener for when to user scrolls to the top to load the next child
      this.getChatMessagesDiv().addEventListener("scroll", this.handleScroll);
    },
    async loadTicket(ticketId, addToEnd) {
      if (!ticketId) {
        console.error("loadTicket: ticketId is not defined");
        return;
      }

      // check if a ticket with the given id is already in tickets
      var foundTicket = this.tickets.find(
        (ticket) => ticket.primaryKey == ticketId
      );
      if (foundTicket) {
        // console.log(
        //   "loadTicket: Ticket with id ",
        //   ticketId,
        //   " is already in tickets"
        // );
        return;
      }

      this.getMessagesLoadingSpinner().style.display = "block";

      const ticket = await ticketFunctions.loadTicket(ticketId);
      if (ticket.childIds && ticket.childIds.length > 0) {
        for (let i = ticket.childIds.length - 1; i >= 0; i--) {
          const childId = ticket.childIds[i];
          await this.loadTicket(childId);
        }
      }

      // check again if a ticket with the given id is already in tickets
      foundTicket = this.tickets.find(
        (ticket) => ticket.primaryKey == ticketId
      );
      if (foundTicket) {
        // console.log(
        //   "loadTicket 2: Ticket with id ",
        //   ticketId,
        //   " is already in tickets"
        // );
        return;
      }

      if (addToEnd) {
        this.tickets = this.tickets.concat([ticket]);
      } else {
        this.tickets = [ticket].concat(this.tickets);
      }

      this.getMessagesLoadingSpinner().style.display = "none";
    },
    async loadNextTicket() {
      for (let i = this.ticketProcess.childIds.length - 1; i >= 0; i--) {
        const childId = this.ticketProcess.childIds[i];
        if (
          this.tickets.find((ticket) => ticket.primaryKey == childId) == null
        ) {
          await this.loadTicket(childId);
          return true;
        }
      }
      return false;
    },
    async loadNewestTicket() {
      if (!this.ticketProcess) {
        console.error("loadNewsestTicket: No process");
        return false;
      }
      var childIds = this.ticketProcess.childIds;
      if (!childIds) {
        console.error(
          "loadNewestTicket: No childIds for this process:",
          this.ticketProcess
        );
        return false;
      }

      await this.loadTicket(childIds[childIds.length - 1], true);

      return true;
    },
    handleScroll(event) {
      if (event.type != "scroll") return;
      var chatMessages = event.target;
      if (chatMessages.scrollTop == 0) {
        // console.log("load next ticket tickets:", this.tickets);
        this.loadNextTicket();
        chatMessages.scrollTop = 2;
      }
    },
  },
  mounted: function () {
    // var test = document.getElementById("searchMenu");
    // console.log("Test searchMenu: " + test);

    this.tickets = [];
    this.beginnLoadingTickets();
  },
  dispatch() {
    this.getChatMessagesDiv().removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#chatMessages {
  height: 100%;
  overflow-y: auto;
}
</style>