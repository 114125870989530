<template>
  <div class="ticketMenuParent">
    <p class="first">
      <!-- {{ ticketProcess }} -->
      <span class="boldText">{{ $t("ticket.ticektCode") }}</span>
      <br />
      <span>{{ ticketProcess.ticketCode }}</span>
    </p>
    <p v-if="!isOwner || !editSubject">
      <span class="boldText">{{ $t("ticket.subject") }}</span>
      <br />
      <span>{{ ticketProcess.subject }}</span>
      &nbsp;
      <button
        v-if="isOwner"
        class="button input"
        v-on:click="editSubject = true"
      >
        <font-awesome-icon icon="edit" />
      </button>
    </p>
    <form
      v-if="isOwner && editSubject"
      name="ticketMenuSubjectForm"
      @submit="updateSubject"
    >
      <p></p>
      <span class="boldText">{{ $t("ticket.subject") }}</span>
      <br />
      <input class="input" v-model="ticketProcess.subject" required />
      <button class="button input" type="submit">
        <font-awesome-icon icon="save" />
      </button>
    </form>

    <div class="container">
      <span class="boldText">{{ $t("ticket.customer") }}</span>
      <hr />

      <p v-if="!isOwner || !editSender">
        <span class="boldText">{{ $t("mail.mailAddress") }}</span>
        <br />
        <span>{{ ticketProcess.ticketSender.emailAddress }}</span>
        &nbsp;
        <button
          v-if="isOwner"
          class="button input"
          v-on:click="editSender = true"
        >
          <font-awesome-icon icon="edit" />
        </button>
      </p>
      <form
        v-if="isOwner && editSender"
        name="ticketMenuSenderForm"
        @submit="updateSender"
      >
        <span class="boldText">{{ $t("mail.mailAddress") }}</span>
        <br />
        <input
          class="input"
          v-model="ticketProcess.ticketSender.emailAddress"
          @change="checkSenderEmailAdress"
          required
        />
        <button class="button input" type="submit">
          <font-awesome-icon icon="save" />
        </button>
      </form>

      <p v-if="!isOwner || !editCustomerName">
        <span class="boldText">{{ $t("ticketMenu.nameForMailAddress") }}</span>
        <br />
        <span v-if="customerField">{{ customerField }}</span>
        &nbsp;
        <button
          v-if="isOwner"
          class="button input"
          v-on:click="editCustomerName = true"
        >
          <font-awesome-icon icon="edit" />
        </button>
      </p>
      <form
        v-if="isOwner && editCustomerName"
        name="ticketMenuCustomerNameForm"
        @submit="saveCustomer"
      >
        <span class="boldText">{{ $t("ticketMenu.nameForMailAddress") }}</span>
        <br />
        <span class="boldText">{{ $t("ticketMenu.firstName") }}</span>
        <br />
        <input class="input" v-model="customer.firstName" required />
        <br />
        <span class="boldText">{{ $t("ticketMenu.name") }}</span>
        <br />
        <input class="input" v-model="customer.name" required />
        <br />
        <span class="boldText">{{ $t("ticketMenu.organisation") }}</span>
        <br />
        <input class="input" v-model="customer.organisation" required />
        <br />
        <button class="button input" type="submit">
          <font-awesome-icon icon="save" />
        </button>
        <br />
        <nobr>
          <span class="error" v-if="customerErrorMessage">{{
            customerErrorMessage
          }}</span>
        </nobr>
      </form>
    </div>

    <!-- <p>
      <span class="boldText">Gruppe</span>
      <br />
      <span>TODO</span>
    </p> -->
    <p>
      <span class="boldText">{{ $t("ticket.owner") }}</span>
      <br />
      <select
        id="ownerSelect"
        name="ownerSelect"
        class="select input"
        v-model="ticketProcess.owner"
        @change="onOwnerChange($event)"
      >
        <option :value="0">{{ $t("general.nobody") }}</option>
        <option v-for="(user, key) in users" v-bind:key="key" :value="user.id">
          {{ user.name }}
        </option>
      </select>
    </p>
    <p>
      <span class="boldText">{{ $t("ticket.state") }}</span>
      <br />
      <select
        id="statusSelect"
        name="statusSelect"
        class="select input"
        v-model="ticketProcess.state"
        @change="onStateChange($event)"
      >
        <option value="ToDo">{{ $t("states.ToDo") }}</option>
        <option value="Process">{{ $t("states.Process") }}</option>
        <option value="Done">{{ $t("states.Done") }}</option>
        <option value="WaitForInternAnswer">{{ $t("states.WaitForInternAnswer") }}</option>
        <option value="WaitForExternAnswer">{{ $t("states.WaitForExternAnswer") }}</option>
        <option value="WaitForDelivery">{{ $t("states.WaitForDelivery") }}</option>
      </select>
    </p>
    <p>
      <span class="boldText">{{ $tc("ticket.pool", 1) }}</span>
      <br />
      <select
        id="poolSelect"
        name="poolSelect"
        class="select input"
        v-model="ticketProcess.poolPrimaryKey"
        @change="onPoolChange($event)"
      >
        <option
          v-for="(pool, key) in pools"
          v-bind:key="key"
          :value="pool.primaryKey"
        >
          {{ pool.name }}
        </option>
      </select>
    </p>
    <div v-if="canAddFollowUpDate">
      <label for="followUpDate">{{ $t("ticketMenu.followUpDate") }}</label>
      <input
        type="date"
        :value="inputFollowUpDate"
        id="followUpDate"
        name="followUpDate"
      />
      <button class="button input" v-on:click="addFollowUpDateAndSaveState">
        <font-awesome-icon icon="save" />
      </button>
    </div>
    <!-- <p>
      <span class="boldText">Priorität</span>
      <br />
      <span>TODO</span>
    </p> -->
    <div>
      <span>{{ $t("ticketMenu.links") }}:</span>
      <br />
      <template v-if="parentLinks && parentLinks.length > 0">
        <span>{{ $t("ticketMenu.parents") }}:</span>
        <div v-for="link in parentLinks" v-bind:key="link.primaryKey">
          <router-link
            class="linkLink"
            :to="
              $i18nRoute({
                name: 'Ticket',
                params: { processId: link.parentTicketProcessPrimaryKey },
              })
            "
            >({{ link.parentTicketProcessPrimaryKey }})
            {{ link.parentSubject }}</router-link
          >
          &nbsp;
          <button class="button input" v-on:click="removeLink(link)">X</button>
        </div>
      </template>
      <template v-if="childenLinks && childenLinks.length > 0">
        <span>{{ $t("ticketMenu.children") }}:</span>
        <div v-for="link in childenLinks" v-bind:key="link.primaryKey">
          <router-link
            class="linkLink"
            :to="{
              name: 'Ticket',
              params: { processId: link.childTicketProcessPrimaryKey },
            }"
            >({{ link.childTicketProcessPrimaryKey }})
            {{ link.childSubject }}</router-link
          >
          &nbsp;
          <button class="button input" v-on:click="removeLink(link)">X</button>
        </div>
      </template>
      <button
        class="button input"
        v-if="parentLinks.length == 0"
        v-on:click="addLinkClicked()"
      >
        {{ $t("ticketMenu.addLink") }}
      </button>
    </div>
    <!-- Modal -->
    <div id="linkModal" class="modal">
      <div class="modal-content">
        <span class="closeButton" @click="closeLinkModal()">&times;</span>
        <template v-if="ticketProcesses">
          <span>{{ $t("ticketMenu.ticketsToLink") }}:</span>
          <div
            class="ticketProcessOption"
            v-for="(ticketProcess, key) in ticketProcesses"
            v-bind:key="key"
            v-on:click="ticketProcessSelected(ticketProcess)"
          >
            ({{ ticketProcess.primaryKey }})&nbsp;
            {{ ticketProcess.subject }}
          </div>
        </template>
        <span v-if="!ticketProcesses || ticketProcesses.length == 0">{{
          $t("ticketMenu.noTicketsToLink")
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
const TicketStatus = Object.freeze({
  ToDo: 1,
  Process: 2,
  Done: 3,
  WaitForInternAnswer: 4,
  WaitForExternAnswer: 5,
});

import userFunctions from "@/functions/user";
import poolFunctions from "@/functions/pool";
import processFunctions from "@/functions/process";
import customerFunctions from "@/functions/customer";
import utilsFunctions from "@/functions/utils";
import linksFunctions from "@/functions/link";
import { LOADING_STARTED, LOADING_STOPPED } from "@/store/actions/loading";

export default {
  name: "TicketMenu",
  data() {
    return {
      TicketStatus,
      users: {},
      pools: {},
      customer: null,
      editSubject: false,
      editSender: false,
      editCustomerName: false,
      customerErrorMessage: "",
      links: [],
      ticketProcesses: [],
    };
  },
  props: {
    ticketProcess: Object,
  },
  computed: {
    isOwner: function () {
      return this.ticketProcess.owner == this.$store.getters.userId;
    },
    customerField: function () {
      return customerFunctions.buildNameForCustomer(this.customer);
    },
    canAddFollowUpDate: function () {
      if (
        this.ticketProcess.state == "WaitForInternAnswer" ||
        this.ticketProcess.state == "WaitForExternAnswer" ||
        this.ticketProcess.state == "WaitForDelivery"
      ) {
        return true;
      } else {
        return false;
      }
    },
    inputFollowUpDate: function () {
      let followUpDateString = "";
      let followUpDateFromProcess = this.ticketProcess.followUp;

      if (!followUpDateFromProcess) {
        return "";
      }

      followUpDateFromProcess = parseInt(followUpDateFromProcess);

      let followUpDate = new Date(followUpDateFromProcess * 1000);
      if (followUpDate) {
        var month = followUpDate.getMonth() + 1;
        if (month < 10) {
          month = "0" + month;
        }
        var day = followUpDate.getDate();
        if (day < 10) {
          day = "0" + day;
        }

        followUpDateString =
          followUpDate.getFullYear() + "-" + month + "-" + day;
      }

      return followUpDateString;
    },
    parentLinks: function () {
      var parentLinks = [];
      if (this.links) {
        for (const link of this.links) {
          if (
            link.childTicketProcessPrimaryKey == this.ticketProcess.primaryKey
          ) {
            parentLinks.push(link);
          }
        }
      }
      return parentLinks;
    },
    childenLinks: function () {
      var childenLinks = [];
      if (this.links) {
        for (const link of this.links) {
          if (
            link.parentTicketProcessPrimaryKey == this.ticketProcess.primaryKey
          ) {
            childenLinks.push(link);
          }
        }
      }
      return childenLinks;
    },
  },
  watch: {
    ticketProcess: function () {
      this.tryToLoadCustomer();
      this.loadLinks();
    },
  },
  methods: {
    async loadUsers() {
      this.users = await userFunctions.getAppUsers(this.$store);
      this.users.sort((a, b) => (a.name > b.name ? 1 : -1));
      this.$forceUpdate();
    },
    async loadPools() {
      this.pools = await poolFunctions.getPoolsByFilter("All", this.$store);
    },
    async tryToLoadCustomer() {
      // console.log("tryToLoadCustomer ticketProcess:", this.ticketProcess);
      var customer = null;
      if (this.ticketProcess && this.ticketProcess.ticketSender.emailAddress) {
        customer = await customerFunctions.getCustomerByEmailAdress(
          this.ticketProcess.ticketSender.emailAddress.toLowerCase()
        );
      } else {
        console.error(
          "tryToLoadCustomer ticketProcess or sender of it is null. ticketProcess:",
          this.ticketProcess
        );
      }

      // console.log("tryToLoadCustomer customer: ", customer);
      if (customer) {
        this.customer = customer;
      } else {
        this.resetCustomer();
      }
    },
    async loadLinks() {
      this.links = [];
      if (this.ticketProcess && this.ticketProcess.primaryKey) {
        this.links = await linksFunctions.getLinks(
          this.ticketProcess.primaryKey,
          this.$store
        );
      } else {
        console.error(
          "loadLinks ticketProcess or primaryKey of it is null. ticketProcess:",
          this.ticketProcess
        );
      }
    },
    checkSenderEmailAdress(event) {
      utilsFunctions.checkEmailAdress(event.target, this.$t("mail.pleaseEnterAValidEmailAddress"));
    },
    async onOwnerChange() {
      this.editSubject = false;
      this.editSender = false;
      this.editCustomerName = false;

      await this.updateTicketProcess();
    },
    async onStateChange() {
      if (!this.canAddFollowUpDate) {
        this.ticketProcess.followUp = null;
        await this.updateTicketProcess();
      }
    },
    async onPoolChange() {
      await this.updateTicketProcess();
    },
    async addFollowUpDateAndSaveState() {
      let followUpDateString = document.getElementById("followUpDate").value;

      let dateParts = followUpDateString.split("-");
      var year = Number(dateParts[0]);
      var month = Number(dateParts[1]) - 1;
      var day = Number(dateParts[2]);
      let followUpDate = new Date(Date.UTC(year, month, day, 0));

      this.ticketProcess.followUp = Math.floor(followUpDate.getTime() / 1000);
      await this.updateTicketProcess();
    },
    async updateSubject(e) {
      e.preventDefault();
      this.editSubject = false;
      this.updateTicketProcess();
    },
    async saveCustomer(e) {
      e.preventDefault();
      this.customer.emailAddress =
        this.ticketProcess.ticketSender.emailAddress.toLowerCase();
      if (!this.customer.primaryKey) {
        this.addCustomer();
      } else {
        this.updateCustomer();
      }
    },
    async addCustomer() {
      let success = await customerFunctions.addCustomer(
        this.customer,
        this.$store
      );
      // console.log("addCustomer success: ", success);
      if (success) {
        this.tryToLoadCustomer();
        this.customerErrorMessage = "";
        this.editCustomerName = false;
      } else {
        this.customerErrorMessage = this.$t("mail.invalidEmailAddress");
      }
    },
    async updateCustomer() {
      var success = await customerFunctions.updateCustomer(
        this.customer,
        this.$store
      );
      // console.log("updateCustomerName success: ", success);
      if (success) {
        this.customerErrorMessage = "";
        this.editCustomerName = false;
      } else {
        this.customerErrorMessage = this.$t("mail.invalidEmailAddress");
      }
    },
    async updateSender(e) {
      e.preventDefault();
      this.tryToLoadCustomer();
      this.updateTicketProcess();
      this.editSender = false;
      if (
        this.ticketProcess.ticketSender.emailAddress.toLowerCase() !=
        this.customer.emailAddress.toLowerCase()
      ) {
        this.resetCustomer();
      }
    },
    async updateTicketProcess() {
      await processFunctions.updateProcess(this.ticketProcess, this.$store);
    },
    resetCustomer() {
      this.customer = new Object();
      this.customer.firstName = null;
      this.customer.name = null;
      this.customer.organisation = null;
      this.customer.emailAddress = null;
    },
    async addLinkClicked() {
      this.$store.dispatch(LOADING_STARTED);
      this.ticketProcesses = await processFunctions.getProcessesByFilter(
        "Open"
      );
      this.$store.dispatch(LOADING_STOPPED);
      this.ticketProcesses = this.ticketProcesses.filter((process) => {
        var allreadyLinked = false;
        for (const link of this.parentLinks) {
          if (link.parentTicketProcessPrimaryKey == process.primaryKey) {
            allreadyLinked = true;
            break;
          }
        }
        if (!allreadyLinked) {
          for (const link of this.childenLinks) {
            if (link.childTicketProcessPrimaryKey == process.primaryKey) {
              allreadyLinked = true;
              break;
            }
          }
        }

        return (
          process.primaryKey != this.ticketProcess.primaryKey && !allreadyLinked
        );
      });
      this.openLinkModal();
    },
    async removeLink(link) {
      await linksFunctions.removeLink(link.primaryKey, this.$store);
      this.loadLinks();
    },
    async ticketProcessSelected(selectedTicketProcess) {
      // console.log(
      //   "ticketProcessSelected selectedTicketProcess:",
      //   selectedTicketProcess
      // );
      var link = new Object();
      link.parentTicketProcessPrimaryKey = this.ticketProcess.primaryKey;
      link.parentSubject = this.ticketProcess.subject;
      link.childTicketProcessPrimaryKey = selectedTicketProcess.primaryKey;
      link.childSubject = selectedTicketProcess.subject;

      // console.log("link to add:", link);
      await linksFunctions.addLink([link], this.$store);
      // TODO add it local if there where a primaryKey
      // if (this.links && this.links.length >= 1) {
      //   this.links.push(link);
      // } else {
      //   this.links = [link];
      // }
      // console.log("ticketProcessSelected links:", this.links);

      // close child ticketProcess
      selectedTicketProcess.state = "Done";
      await processFunctions.updateProcess(selectedTicketProcess, this.$store);

      this.closeLinkModal();
      this.loadLinks();
    },
    openLinkModal() {
      var linkModal = document.getElementById("linkModal");
      linkModal.style.display = "block";
    },
    closeLinkModal() {
      var linkModal = document.getElementById("linkModal");
      linkModal.style.display = "none";
    },
  },
  created() {
    this.resetCustomer();
  },
  beforeMount() {
    this.loadUsers();
    this.loadPools();
    this.tryToLoadCustomer();
    this.loadLinks();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ticketMenuParent {
  padding: 1em;
}

.select {
  background: white;
}

.linkLink {
  margin: 0.5em;
}

.ticketProcessOption {
  cursor: pointer;
  margin: 1em;
  padding: 1em;
  background-color: #e4e4e4;
  border-radius: 1em;
}
</style>