<template>
  <div class="middleRightParent" v-if="ticketProcess">
    <div class="titleBar">
      <!-- <p>ProcessId: {{ processId }}</p>
      <p>ticketProcess: {{ ticketProcess }}</p> -->
      <span>
        <b>{{ $t("ticket.ticektCode") }}:</b> {{ ticketProcess.ticketCode }}
        <br />
        <b>{{ $t("ticket.subject") }}:</b> {{ ticketProcess.subject }}
      </span>
      <span class="menu-button icon-button" @click="toggleMenu()"
        ><font-awesome-icon icon="ellipsis-v"
      /></span>
    </div>
    <div class="ticketContent">
      <div class="chat">
        <div class="chatHistory">
          <ChatHistory
            ref="chatHistory"
            v-bind:ticket-process="ticketProcess"
            @answer="answer"
          />
        </div>
        <div class="newTicketInput">
          <NewTicketInput
            v-bind:ticket-process="ticketProcess"
            v-bind:answer-ticket="answerTicket"
            v-bind:answer-type="answerType"
            @newAnswerAdded="newAnswerAdded"
            @cancelAnswer="cancelAnswer"
          />
        </div>
      </div>
      <div class="ticketMenu" v-bind:class="{ collapsed: !showMenu }">
        <TicketMenu v-bind:ticket-process="ticketProcess" />
      </div>
    </div>
  </div>
</template>

<script>
import ChatHistory from "@/components/ChatHistory";
import NewTicketInput from "@/components/NewTicketInput";
import TicketMenu from "@/components/TicketMenu";
import processFunctions from "@/functions/process";

export default {
  name: "Ticket",
  components: {
    ChatHistory,
    NewTicketInput,
    TicketMenu,
  },
  data() {
    return {
      ticketProcess: null,
      showMenu: false,
      answerTicket: null,
      answerType: null,
    };
  },
  computed: {
    processId: function () {
      return this.$route.params.processId;
    },
  },
  watch: {
    processId: function () {
      this.loadProcess();
    },
  },
  methods: {
    async loadProcess() {
      this.ticketProcess = await processFunctions.loadProcess(
        this.processId,
        this.$store
      );
      // console.log("Ticket loadProcess: ", this.ticketProcess);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    answer(ticket, answerType) {
      this.answerTicket = ticket;
      this.answerType = answerType;
    },
    async newAnswerAdded() {
      await this.loadProcess();
      this.$refs.chatHistory.loadNewestTicket();
    },
    cancelAnswer() {
      this.answerTicket = null;
      this.answerType = null;
    },
  },
  created: function () {
    this.loadProcess();
  },
};
</script>

<style lang="scss" scoped>
.middleRightParent {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.titleBar {
  display: flex;
  background: #333;
  padding: 0.5em;
  color: #e4e4e4;
  z-index: 10;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  align-items: center;

  .menu-button {
    display: none;
    font-size: 2em;
    color: #e4e4e4;
    margin-left: auto;
    padding: 0em;
  }
}

// Only Safari 10.1+
@media not all and (min-resolution: 0.001dpcm) {
  .titleBar {
    padding: 1em;
  }
}

.ticketContent {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.chat {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  flex-grow: 1;
}

.chatHistory {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
}

.ticketMenu {
  width: min-content;
  background: #e4e4e4;
  color: #333;
  flex-shrink: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  overflow-y: auto;
}

// .newTicketInput {
//    display: flex;
// }

@media (max-width: 767px) {
  .ticketMenu {
    // width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    // display: none;

    &.collapsed {
      display: none;
    }
  }

  .titleBar {
    .menu-button {
      display: flex;
    }
  }
}
</style>

<style lang="scss">
@media (max-width: 767px) {
  .ticketMenu {
    .first {
      margin-top: 100px;
    }
  }
}
</style>