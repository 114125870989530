<template>
  <div>
    <div class="chatMessageParent">
      <div
        class="nameBubble"
        v-if="
          ticket.type == TicketType.Outgoing ||
          ticket.type == TicketType.Internal
        "
        v-bind:class="{
          internClass: ticket.type == TicketType.Internal,
          externClass: ticket.type == TicketType.Outgoing,
        }"
      >
        <span>{{ shortSenderName }}</span>
      </div>
      <div
        class="message"
        v-bind:class="{
          receiveClass: ticket.type == TicketType.Incoming,
          internClass: ticket.type == TicketType.Internal,
          externClass: ticket.type == TicketType.Outgoing,
        }"
      >
        <iframe
          class="ticketBody"
          :id="ticket.primaryKey"
          frameborder="0"
          scrolling="yes"
        ></iframe>
        <!-- <span class="ticketBody" :id="ticket.primaryKey"></span> -->
        <span
          v-if="showShowButton"
          class="button input"
          @click="showMoreOrLess()"
          >{{ showButtonText }}</span
        >
        <div class="attachmentsParent" v-if="ticket.attachments != null">
          <div>
            {{ $t("ticket.attachments") }}:
            <hr />
          </div>
          <div
            class="attachment"
            v-for="(attachment, index) in ticket.attachments"
            v-bind:index="index"
            v-bind:key="attachment.primaryKey"
          >
            <span class="button" @click="downloadAttachment(attachment)">{{
              attachment.fileName
            }}</span>
          </div>
        </div>
        <span class="messageFooter">
          <button
            class="button input answerButton"
            @click="answer(ticket, 'answer')"
          >
            {{ $t("mail.answer") }}
          </button>
          <button
            class="button input answerButton"
            @click="answer(ticket, 'answerAll')"
          >
            {{ $t("mail.answerAll") }}
          </button>
          <button
            class="button input answerButton"
            @click="answer(ticket, 'forwarding')"
          >
            {{ $t("mail.forwarding") }}
          </button>
          <br />
          <span v-if="ticket.type == TicketType.Incoming">{{
            $t("mail.received")
          }}</span>
          <span v-else-if="ticket.type == TicketType.Internal">{{
            $t("ticket.internal")
          }}</span>
          <span v-else-if="ticket.type == TicketType.Outgoing">{{
            $t("mail.sent")
          }}</span>
          <br />
          <span v-if="toAdressesString && ticket.type != TicketType.Internal">
            {{ $t("mail.to") }}: {{ toAdressesString }}
            <br />
          </span>
          <span v-if="ccAdressesString && ticket.type != TicketType.Internal">
            {{ $t("mail.cc") }}: {{ ccAdressesString }}
            <br />
          </span>
          <span
            v-if="
              ticket.ticketSender &&
              ticket.ticketSender.emailAddress &&
              ticket.type == TicketType.Incoming
            "
          >
            {{ $t("chatMessage.from") }}: {{ ticket.ticketSender.emailAddress }}
            <br />
          </span>
          <span v-if="agentName">
            <span v-if="ticket.type == TicketType.Incoming">{{
              $t("chatMessage.createdFrom")
            }}</span>
            {{ $t("chatMessage.agent") }}: {{ agentName }}
            <br />
          </span>
          <span>
            {{ $t("chatMessage.at") }}:
            {{ new Date(ticket.creationDate * 1000).toLocaleString() }}
          </span>
          <span v-if="processPrimaryKey != ticket.ticketProcessPrimaryKey"
            ><br />{{ $t("chatMessage.mergedWithTicketCode") }}
            {{ ticket.ticketProcessPrimaryKey }}</span
          >
          <!-- <span><br />primaryKey: {{ ticket.primaryKey }}</span> -->
        </span>
      </div>
      <div
        class="nameBubble"
        v-if="ticket.type == TicketType.Incoming"
        v-bind:class="{ receiveClass: ticket.type == TicketType.Incoming }"
      >
        <span>{{ shortSenderName }}</span>
      </div>
    </div>

    <ChatMessage
      v-for="(childticket, index) in ticket.children"
      v-bind:index="index"
      v-bind:key="childticket.primaryKey"
      v-bind:ticket="childticket"
      @answer="answer"
    />
  </div>
</template>

<script>
import userFunctions from "@/functions/user";
import ticketFunctions from "@/functions/ticket";
import recipientFunctions from "@/functions/recipient";
import utilsFunctions from "@/functions/utils";
// import sanitizeHtml from 'sanitize-html';

const TicketType = Object.freeze({
  Incoming: "Incoming",
  Outgoing: "Outgoing",
  Internal: "Internal",
});

export default {
  name: "ChatMessage",
  data() {
    return {
      TicketType,
      agentName: null,
      toAdressesString: null,
      ccAdressesString: null,
      showShowButton: false,
      showMore: "Mehr anzeigen",
      showLess: "Weniger anzeigen",
      showButtonText: "Mehr anzeigen",
    };
  },
  props: {
    ticket: Object,
    processPrimaryKey: Number,
  },
  computed: {
    shortSenderName: function () {
      if (
        this.ticket.ticketSender &&
        this.ticket.ticketSender.emailAddress &&
        this.ticket.type == TicketType.Incoming
      ) {
        return this.ticket.ticketSender.emailAddress.substring(0, 3);
      } else if (this.agentName) {
        return this.agentName.substring(0, 3);
      } else {
        return "";
      }
    },
  },
  methods: {
    async downloadAttachment(attachment) {
      // console.log("downloadAttachment: attachment: ", attachment);
      let fileStream = await ticketFunctions.downloadFile(
        attachment,
        this.$store
      );

      this.showFile(fileStream, attachment.fileName);
    },
    showFile(blob, fileName) {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      var newBlob = new Blob([blob], { type: "application/octet-stream" });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob, fileName);
        return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      // console.log("data url: ", data);
      var link = document.createElement("a");
      link.href = data;
      link.download = fileName;
      link.click();
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);
    },
    fillRecipientsStrings() {
      // console.log(
      //   "fillRecipientsStrings ticket.recipients:",
      //   this.ticket.recipients
      // );
      this.toAdressesString = recipientFunctions.generateRecipientsStringBy(
        this.ticket.recipients,
        "To"
      );
      this.ccAdressesString = recipientFunctions.generateRecipientsStringBy(
        this.ticket.recipients,
        "Cc"
      );
    },
    async tryGetAgentName(agentId) {
      if (agentId <= 0) {
        this.agentName = null;
        return;
      }

      var agents = await userFunctions.getUsers([agentId]);

      if (!agents || agents.length < 1) {
        console.error(
          "There was an error when trying to get the user for the agentId " +
            agentId
        );
        this.agentName = null;
        return;
      }

      this.agentName = agents[0].name;
    },
    answer(ticket, answerType) {
      this.$emit("answer", ticket, answerType);
    },
    setBody() {
      var body = null;
      if (this.ticket.htmlBody) {
        body = this.ticket.htmlBody;
      } else {
        body = this.ticket.textBody;
      }

      var bodyIFrameElement = document.getElementById(this.ticket.primaryKey);
      var bodyIFrameDoc =
        bodyIFrameElement.contentDocument ||
        bodyIFrameElement.contentWindow.document;

      if (body && utilsFunctions.isHTML(body)) {
        // it is HTML
        body = this.replaceAttachmentsURL(body);
        // const saveBody = sanitizeHtml(body);

        var bodyDiv = document.createElement("div");
        bodyDiv.innerHTML = body;
        this.makeLinksOpenInNewTab(bodyDiv);

        bodyIFrameDoc.body.innerHTML = bodyDiv.innerHTML;
        bodyIFrameElement.srcdoc = bodyDiv.innerHTML;
      } else if (body) {
        // it is row text
        bodyIFrameDoc.body.innerText = body;
      } else {
        bodyIFrameDoc.body.innerText = "";
      }

      // set height
      let bodyHeight =
        bodyIFrameElement.contentWindow.document.body.scrollHeight;

      bodyIFrameElement.height = bodyHeight + (bodyHeight * 0.2);

      this.initShowButton();
    },
    initShowButton() {
      var win = window,
        doc = document,
        docElem = doc.documentElement,
        body = doc.getElementsByTagName("body")[0],
        height = win.innerHeight || docElem.clientHeight || body.clientHeight;

      var bodyHeight = document.getElementById(
        this.ticket.primaryKey
      ).clientHeight;

      this.showShowButton = bodyHeight >= height * 0.45;
    },
    showMoreOrLess() {
      var bodyElement = document.getElementById(this.ticket.primaryKey);
      if (this.showButtonText == this.showMore) {
        bodyElement.style.maxHeight = "none";
        this.showButtonText = this.showLess;
      } else {
        bodyElement.style.maxHeight = "50vh";
        this.showButtonText = this.showMore;
      }
    },
    makeLinksOpenInNewTab(element) {
      var links = element.getElementsByTagName("a");
      for (const link of links) {
        if (!link.getAttribute("target")) {
          link.setAttribute("target", "_blank");
        }
      }
    },
    replaceAttachmentsURL(body) {
      return body.replaceAll(
        "TICKET_SYSTEM_URL",
        process.env.VUE_APP_TICKET_SERVICE_URL
      );
    },
  },
  mounted: function () {
    // console.log("ChatMessage mounted ticket: ", this.ticket);

    this.setBody();
    this.fillRecipientsStrings();
    this.tryGetAgentName(this.ticket.agent);
  },
};
</script>

<style scoped>
.chatMessageParent {
  display: flex;
  margin: 1em;
}

.attachmentsParent {
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  padding: 1em;
  background-color: #f7f5f5;
  border-radius: 0.25em;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
}

.attachment {
  display: inline-flex;
  margin: 0.25em;
}

.message {
  flex-grow: 1;
  padding: 1em;
  /* padding-right: 0.5em;
  padding-bottom: 0.5em; */
  margin-left: 0.5em;
  border-radius: 1em;
  border-bottom-left-radius: 0.25em;
  display: flex;
  flex-direction: column;
}

.message.receiveClass {
  margin-left: 0;
  margin-right: 0.5em;
  border-radius: 1em;
  border-bottom-right-radius: 0.25em;
}

.nameBubble {
  height: 4em;
  width: 4em;
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.message,
.nameBubble {
  box-sizing: border-box;
}

.ticketBody {
  flex-grow: 1;
  max-height: 50vh;
  /* max-width: 80vw; */
  overflow-y: hidden;
  overflow-x: auto;
}

/* @media (min-width: 767px) {
  .ticketBody {
    max-width: 60vw;
  }
} */

.message,
.nameBubble {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
}

.messageFooter {
  text-align: right;
  color: rgba(0, 0, 0, 0.75);
}

.receiveClass {
  background-color: #e4e4e4;
}

.internClass {
  background-color: #84b57e;
}

.externClass {
  border: 0;
  background-color: #e4e4e4;
  box-shadow: 0 0 0 3px #84b57e inset;
}

.answerButton {
  margin: 0.5em;
}
</style>