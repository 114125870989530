import axios from 'axios';
import { LOADING_STARTED, LOADING_STOPPED } from "@/store/actions/loading";

var linkFunctions = {
    async getLinks(processId, store) {
        store.dispatch(LOADING_STARTED);

        let links = null;

        await axios
            .get(
                process.env.VUE_APP_TICKET_SERVICE_API_URL + "/api/link/byprocessid/" + processId
            )
            .then((resp) => {
                // console.log("getLinks resp: ", resp);
                links = resp.data;
            })
            .catch((error) => {
                error.handleGlobally && error.handleGlobally();
            });

        store.dispatch(LOADING_STOPPED);

        return links;
    },
    async addLink(links, store) {
        store.dispatch(LOADING_STARTED);

        let success = false;

        await axios
            .post(
                process.env.VUE_APP_TICKET_SERVICE_API_URL + "/api/link",
                links
            )
            .then(() => {
                success = true;
            })
            .catch((error) => {
                error.handleGlobally && error.handleGlobally();
            });

        store.dispatch(LOADING_STOPPED);

        return success;
    },
    async removeLink(linkId, store) {
        store.dispatch(LOADING_STARTED);

        let success = false;

        await axios
            .delete(
                process.env.VUE_APP_TICKET_SERVICE_API_URL + "/api/link/" + linkId
            )
            .then(() => {
                success = true;
            })
            .catch((error) => {
                error.handleGlobally && error.handleGlobally();
            });

        store.dispatch(LOADING_STOPPED);

        return success;
    },
}

export default linkFunctions;